<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <h3>Scope of Work Templates</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="templateRequest.status"
                        label="Active"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="templateRequest.public"
                        label="Public"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="2"
                    md="6"
                    sm="6"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                style="width: 100% !important;"
                                outlined
                                v-on="on"
                                @click="showImportTemplate()"
                            >
                                Import Public Template
                            </v-btn>
                        </template>
                        <span>Import template from other user</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Name</label>
                    <v-text-field
                        v-model="templateRequest.name" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                    style="padding-left: 0px !important;"
                >
                    <v-card-text>

                        <v-card
                            class="mx-auto"
                        >
                            <v-col 
                                cols="12"
                                lg="12"
                                md="4"
                                sm="4"
                            >
                                <v-text-field
                                    v-model="searchProduct"
                                    label="Search Product"
                                    prepend-inner-icon="mdi mdi-magnify"
                                    single-line
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                Products
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">
                                <v-treeview
                                    :items="listTreeViewProduct"
                                    :search="searchProduct"
                                    :open.sync="arrayNodeProduct"
                                    @update:open="onOpenTreeViewProduct"
                                    :transition="true"
                                    open-on-click
                                    rounded
                                    hoverable
                                >
                                    <template v-slot:prepend="{ item }">
                                        <v-icon style="color: var(--color__main);" v-if="item.type == 'Category'">
                                            mdi-shape-outline
                                        </v-icon>
                                        <v-icon style="color: var(--color__main);" v-if="item.type == 'SubCategory'">
                                            mdi-content-duplicate
                                        </v-icon>
                                        <v-icon style="color: var(--color__cinza_escuro);" v-if="item.type == 'Product'">
                                            mdi-package-variant-closed
                                        </v-icon>
                                    </template>
                                    <template v-slot:label="{ item }">
                                        <v-row>
                                            <v-col
                                                cols="9"
                                                style="display: flex; align-items: center;"
                                            >
                                                <span :style="item.type == 'Category' || item.type == 'SubCategory' ? 'color: var(--color__main);' : 'color: var(--color__cinza_escuro);'"> 
                                                    {{ item.name }} 
                                                    <span style="font-size: 20px; color: red; font-weight: bold;" v-if="isProductMandatory(item) == true"> *</span>
                                                    <span style="font-size: 10px;" v-if="item.details != '' && item.type == 'Product'"><br />{{ item.details }}</span>

                                                    <v-tooltip 
                                                        bottom
                                                        v-if="item.highlight == 1"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon 
                                                                v-on="on"
                                                                v-if="item.highlight == 1"
                                                                style="color: var(--color__alert); margin-left: 5px;" 
                                                            >
                                                                mdi-alert-octagram-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>{{ item.highlightDescription }}</span>
                                                    </v-tooltip>

                                                    <v-tooltip 
                                                        bottom
                                                        v-if="item.customOrder == 1"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon 
                                                                v-on="on"
                                                                v-if="item.customOrder == 1"
                                                                style="color: var(--color__alert); margin-left: 5px;" 
                                                            >
                                                                mdi-package-variant-plus
                                                            </v-icon>
                                                        </template>
                                                        <span>Custom Order</span>
                                                    </v-tooltip>

                                                    <v-tooltip 
                                                        bottom
                                                        v-if="item.stylable == 1"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon 
                                                                v-on="on"
                                                                v-if="item.stylable == 1"
                                                                style="color: var(--color__blue); margin-left: 5px;" 
                                                            >
                                                                mdi-palette-swatch-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>Stylable</span>
                                                    </v-tooltip>
                                                    
                                                </span>
                                            </v-col>
                                            <v-col
                                                style="text-align:right;"
                                                v-if="item.type == 'Category' || item.type == 'SubCategory'"
                                                cols="3"
                                            >
                                                {{ item.children.filter(chd => chd.qty != "0.00" && chd.qty != "").length }} / {{ item.children.length }}
                                            </v-col>
                                            <v-col
                                                v-if="item.type == 'Product'"
                                                cols="3"
                                            >
                                                <v-text-field
                                                    v-if="item.unityFieldType == 1"
                                                    :id="'productQty' + item.idTable"
                                                    name="qtyField"
                                                    style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                    v-model="item.qty"
                                                    v-formatMoney="moneyFormat"
                                                    :suffix="item.unitySymbol"
                                                    :rules=[validations.mandatoryProduct(item)]
                                                    dense
                                                    single-line
                                                    outlined
                                                    @blur="updateItemsProduct"
                                                >
                                                </v-text-field>
                                                <v-text-field
                                                    v-else
                                                    :id="'productQty' + item.idTable"
                                                    name="qtyField"
                                                    style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                    v-mask="'####'"
                                                    v-model="item.qty"
                                                    :suffix="item.unitySymbol"
                                                    :rules=[validations.mandatoryProduct(item)]
                                                    dense
                                                    single-line
                                                    outlined
                                                    @blur="updateItemsProduct"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-treeview>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-col>
        
                <v-divider vertical></v-divider>

                <v-col
                    cols="12"
                    md="7"
                >
                    <v-card-text>

                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                <v-row>
                                    <v-col
                                        cols="9"
                                    >
                                        Selected Items
                                    </v-col>
                                    <v-col
                                        style="text-align:right;"
                                        cols="3"
                                    >
                                        <v-tooltip top v-if="!showMoreDetailProducts">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="showMoreDetailProducts = true"  v-if="!showMoreDetailProducts">mdi-eye-off</v-icon>
                                            </template>
                                            <span>See more details</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="showMoreDetailProducts">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="showMoreDetailProducts = false" v-if="showMoreDetailProducts">mdi-eye</v-icon>
                                            </template>
                                            <span>Hide more details</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">
                                <v-simple-table
                                    fixed-header
                                    style="cursor: pointer;"
                                >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Category
                                                </th>
                                                <th class="text-left">
                                                    Sub Category
                                                </th>
                                                <th class="text-left">
                                                    Product
                                                </th>
                                                <th class="text-center">
                                                    Qtd
                                                </th>
                                                <th class="text-right" v-if="showMoreDetailProducts">
                                                    Price
                                                </th>
                                                <th class="text-right" v-if="showMoreDetailProducts">
                                                    Total
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(item, index) in listProductSelecteds.filter(prd => prd.automation == false)" :key="index"
                                                @click="focusInput(item)"
                                            >
                                                <td>{{ item.categoryDescription }}</td>
                                                <td>{{ item.subCategoryDescription }}</td>
                                                <td>{{ item.productDescription }}</td>
                                                <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.price }}</td>
                                                <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.total }}</td>
                                                <td>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete Item</span>
                                                    </v-tooltip>
                                                </td>
                                                <td>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>View Image</span>
                                                    </v-tooltip>

                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>View Docs</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>

                        </v-card>
                        
                    </v-card-text>

                    <v-card-text
                        style="margin-top: 10px;"
                    >
                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                Automation
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">
                                <label>Automation Controller</label>
                                <v-simple-table
                                    fixed-header
                                    style="cursor: pointer;"
                                >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Sub Category
                                                </th>
                                                <th class="text-left" >
                                                    Product
                                                </th>
                                                <th class="text-center">
                                                    Qty
                                                </th>
                                                <th class="text-center">
                                                    Channel Qty
                                                </th>
                                                <th class="text-right" v-if="showMoreDetailProducts">
                                                    Price
                                                </th>
                                                <th class="text-right" v-if="showMoreDetailProducts">
                                                    Total
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(item, index) in listProductSelecteds.filter(prd => prd.automationCenter == 1)" :key="index"
                                                @click="focusInput(item)"
                                            >
                                                <td>{{ item.subCategoryDescription }}</td>
                                                <td>{{ item.productDescription }}</td>
                                                <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                <td class="text-center">{{ item.channelQty }}</td>
                                                <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.price }}</td>
                                                <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.total }}</td>
                                                <td>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete Item</span>
                                                    </v-tooltip>
                                                </td>
                                                <td>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                :style="item.note != null && item.note != undefined && item.note != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="showItemNote(item, 'PRODUCT')">mdi-message-bulleted</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Add/Remove Notes</span>
                                                    </v-tooltip>
                                                </td>
                                                <td>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>View Image</span>
                                                    </v-tooltip>

                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>View Docs</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>

                            <v-card-text style="margin-top: 20px;">
                                <label>Automated Items</label><label :style="automationItemsIsValid == false ? 'color: var(--color__red);' : ''">- [{{ qtyChannels }} channels available]</label>
                                <v-simple-table
                                    fixed-header
                                    style="cursor: pointer;"
                                >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Sub Category
                                                </th>
                                                <th class="text-left" >
                                                    Product
                                                </th>
                                                <th class="text-center">
                                                    Qty
                                                </th>
                                                <th class="text-right" v-if="showMoreDetailProducts">
                                                    Price
                                                </th>
                                                <th class="text-right" v-if="showMoreDetailProducts">
                                                    Total
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(item, index) in listProductSelecteds.filter(prd => prd.automatable == 1)" :key="index"
                                                @click="focusInput(item)"
                                            >
                                                <td>{{ item.subCategoryDescription }}</td>
                                                <td>{{ item.productDescription }}</td>
                                                <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.price }}</td>
                                                <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.total }}</td>
                                                <td>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete Item</span>
                                                    </v-tooltip>
                                                </td>
                                                <td>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                :style="item.note != null && item.note != undefined && item.note != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="showItemNote(item, 'PRODUCT')">mdi-message-bulleted</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Add/Remove Notes</span>
                                                    </v-tooltip>
                                                </td>
                                                <td>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>View Image</span>
                                                    </v-tooltip>

                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                class="btnActions"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>View Docs</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>

                        </v-card>
                        
                    </v-card-text>

                    <v-card-text
                        style="margin-top: 10px;"
                    >
                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title 
                                class="text-h5 lighten-2" 
                                style="border-bottom: solid 1px var(--color__cinza); display: block; text-align: center;"
                            >
                                <label style="font-size: 20px !important;"> Grand Total </label> 
                                <label style="color: var(--color__main); font-size: 20px !important;">{{ grandTotalProducts }}</label>
                            </v-card-title>
                        </v-card>
                        
                    </v-card-text>
                </v-col>

            </v-row>

            <ActionButtons
                :request="templateRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

            <ImportTemplate 
                v-on:methodConfirmToCall="dialogImportTemplate.methodToCall"
                :showImportTemplate.sync="dialogImportTemplate.show"
                :idAux="dialogImportTemplate.idAux"
            />
        </v-form>

        <ShowFiles 
            :showFiles.sync="dialogShowFiles.show"
            :reload="dialogShowFiles.reload"
            :listParams="dialogShowFiles.listParams"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ImportTemplate from "@/components/Template/ImportTemplate";
    import ShowFiles from "@/components/File/ShowFiles";
    import { formaterDecimalBR } from '@/utilities/Utils';
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ImportTemplate,
            ShowFiles
        },

        data: vm => ({

            validForm: true,

			templateRequest: {
                id: 0,
                status: 1,
                public: 0,
                name: "",
                listItemRequest: []
            },

            listProductSelecteds: [],

            arrayNodeProduct: [],
            searchProduct: "",
            listTreeViewProduct: [],

            qtyFormat: {
                prefix: '',
                suffix: '',
                thousands: '',
                decimal: '.',
                precision: 2,
            },

            showMoreDetailProducts: false,
            grandTotalProducts: "$ 0.00",

            dialogImportTemplate: {
                show: false,
                id: 0,
                idAux: 0,
                methodToCall: ( () => {} ),
            },

            dialogShowFiles: {
                show: false,
                reload: 0,
                listParams: []
            },

            loading: false,

            validations: {
                required: required,
                mandatoryProduct (itemProduct) {
                    if (vm.isProductMandatory(itemProduct) == true && (itemProduct.qty == '0.00' || itemProduct.qty == '' || itemProduct.qty == null))
                        return false
                    else
                        return true
                }
            },
            
            og: Math.pow(10, 2),
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {

            qtyChannels() {
                let centralAutomation = this.listProductSelecteds.filter(prd => prd.automationCenter == 1)[0];

                if (centralAutomation != null && centralAutomation != undefined) {
                    return centralAutomation.channelQty;
                }
                else {
                    return 0;
                }
            },

            automationItemsIsValid() {
                let qtyChannelsAvailable = this.qtyChannels;
                let listAutomatableItems = this.listProductSelecteds.filter(prd => prd.automatable == 1);
                let qtyItemsAutomatable = 0;

                listAutomatableItems.forEach(item => {
                    qtyItemsAutomatable += parseInt(item.qty);
                });

                if (qtyItemsAutomatable > qtyChannelsAvailable) {
                    return false;
                }
                else {
                    return true;
                }
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("templateModule/GetById", this.id);

                    this.templateRequest = response.result;
                }
            },

            async getLists() {
                
                this.listTreeViewProduct = await this.$store.dispatch("productModule/ListProductsToScopeOfWork", { id: this.id, type: 'TEMPLATE', showOnlyCreatedItems: false, fromHistory: false });
                this.updateItemsProduct();
            },

            async updateItemsProduct() {
                                
                this.listProductSelecteds = [];
                let grandTotal = 0;
                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {
                            
                                if (itemProduct.qty != null && itemProduct.qty != undefined && itemProduct.qty != "0.00" && itemProduct.qty != "" ) {

                                    let qty = parseFloat(itemProduct.qty == '' ? 0 : itemProduct.qty);
                                    
                                    if (qty <= 0) {
                                        this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                        itemProduct.qty = '';
                                    }
                                    else {

                                        let total = (qty * itemProduct.price).toFixed(2);

                                        this.listProductSelecteds.push({
                                            id: itemProduct.idServiceItem,
                                            idService: this.id,
                                            idCategory: itemCategory.id,
                                            categoryDescription: itemCategory.name,
                                            idSubCategory: itemSubCategoryOrProduct.id,
                                            subCategoryDescription: itemSubCategoryOrProduct.name,
                                            productDescription: itemProduct.name,
                                            productDetails: itemProduct.details,
                                            idProduct: itemProduct.idTable,
                                            qty: itemProduct.qty,
                                            automation: itemProduct.automatable == 1 || itemProduct.automationCenter == 1,
                                            automatable: itemProduct.automatable,
                                            automationCenter: itemProduct.automationCenter,
                                            channelQty: itemProduct.channelQty,
                                            highlight: itemProduct.highlight,
                                            highlightDescription: itemProduct.highlightDescription,
                                            unitySymbol: itemProduct.unitySymbol,
                                            price: formaterDecimalBR(itemProduct.price),
                                            total: formaterDecimalBR(total),
                                            note: itemProduct.note,
                                            styling: itemProduct.styling,
                                            customOrder: itemProduct.customOrder,
                                            stylable: itemProduct.stylable,
                                            optional: itemProduct.optional
                                        })
                                        grandTotal += parseFloat(total);
                                    }
                                }
                                else {
                                    itemProduct.qty = '';
                                }
                            });
                        }
                        else {
                            
                            if (itemSubCategoryOrProduct.qty != null && itemSubCategoryOrProduct.qty != undefined && itemSubCategoryOrProduct.qty != "0.00" && itemSubCategoryOrProduct.qty != "" ) {

                                let qty = parseFloat(itemSubCategoryOrProduct.qty == '' ? 0 : itemSubCategoryOrProduct.qty);

                                if (qty <= 0) {
                                    this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                    itemSubCategoryOrProduct.qty = ''
                                }
                                else {
                                        
                                    let total = (qty * itemSubCategoryOrProduct.price).toFixed(2);

                                    this.listProductSelecteds.push({
                                        id: itemSubCategoryOrProduct.idServiceItem,
                                        idService: this.id,
                                        idCategory: itemCategory.id,
                                        categoryDescription: itemCategory.name,
                                        idSubCategory: 0,
                                        subCategoryDescription: "",
                                        productDescription: itemSubCategoryOrProduct.name,
                                        productDetails: itemSubCategoryOrProduct.details,
                                        idProduct: itemSubCategoryOrProduct.idTable,
                                        qty: itemSubCategoryOrProduct.qty,
                                        automation: itemSubCategoryOrProduct.automatable == 1 || itemSubCategoryOrProduct.automationCenter == 1,
                                        automatable: itemSubCategoryOrProduct.automatable,
                                        automationCenter: itemSubCategoryOrProduct.automationCenter,
                                        channelQty: itemSubCategoryOrProduct.channelQty,
                                        highlight: itemSubCategoryOrProduct.highlight,
                                        highlightDescription: itemSubCategoryOrProduct.highlightDescription,
                                        unitySymbol: itemSubCategoryOrProduct.unitySymbol,
                                        price: formaterDecimalBR(itemSubCategoryOrProduct.price),
                                        total: formaterDecimalBR(total),
                                        note: itemSubCategoryOrProduct.note,
                                        styling: itemSubCategoryOrProduct.styling,
                                        customOrder: itemSubCategoryOrProduct.customOrder,
                                        stylable: itemSubCategoryOrProduct.stylable,
                                        optional: itemSubCategoryOrProduct.optional
                                    })
                                    grandTotal += parseFloat(total);
                                }
                            }
                            else {
                                itemSubCategoryOrProduct.qty = ''
                            }
                        }
                    });
                });
                
                this.grandTotalProducts = `$ ${formaterDecimalBR(Math.round(Math.floor(grandTotal * this.og) / this.og))}`;
            },

            async showImportTemplate() {
                
                this.dialogImportTemplate = {
                    idAux: Math.random(),
                    show: true,
                    methodToCall: this.importTemplate,
                }
            },

            async importTemplate(templateSelected) {

                this.templateRequest.name = templateSelected.name;

                this.listTreeViewProduct = await this.$store.dispatch("productModule/ListProductsToScopeOfWork", { id: 0, type: 'SERVICE', showOnlyCreatedItems: false, fromHistory: false });
                const response = await this.$store.dispatch("templateModule/GetById", templateSelected.id);

                const listTemplateItem = response.result.listItemResponse;

                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                let itemTemplate = listTemplateItem.filter(tpi => tpi.idProduct == itemProduct.idTable)[0];
                                if (itemTemplate != null && itemTemplate != undefined) {
                                    itemProduct.qty = itemTemplate.qty.toFixed(2).toString()
                                }
                            });
                        }
                        else {
                            
                            let itemTemplate = listTemplateItem.filter(tpi => tpi.idProduct == itemSubCategoryOrProduct.idTable)[0];
                            if (itemTemplate != null && itemTemplate != undefined) {
                                itemSubCategoryOrProduct.qty = itemTemplate.qty.toFixed(2).toString()
                            }
                        }
                    });
                });

                this.updateItemsProduct();
            },

            async cancel() {
                this.$router.push({ path: "/sales/templateList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else { 
                    
                    const qtyChannelsAvailable = this.qtyChannels;
                    let hasAutomationAndHasMoreOneCentral = false;
                    let hasAutomationAndMoreItemsThanCentralChannel = false;

                    if (qtyChannelsAvailable > 0) {
                        const qtyCentral = this.listProductSelecteds.filter(prd => prd.automationCenter == 1);   

                        hasAutomationAndHasMoreOneCentral = qtyCentral.length > 1;

                        if (this.automationItemsIsValid == false) {
                            hasAutomationAndMoreItemsThanCentralChannel = true;
                        }
                    }
                
                    if (hasAutomationAndHasMoreOneCentral == true) {
                        this.showToast("error", "Error!", "Only one automation center can be selected.");
                        return false;
                    }
                    else if (hasAutomationAndMoreItemsThanCentralChannel == true) {
                        this.showToast("error", "Error!", "Number of automation items is greater than the number of central channels.");
                        return false;
                    }
                    else if (await this.checkMandatory()) {
                        this.loading = true;
                        this.showLoading();

                        if (this.templateRequest.status === null) {
                            this.templateRequest.status = 0;
                        }

                        if (this.templateRequest.public === null) {
                            this.templateRequest.public = 0;
                        }

                        let listItemRequest = [];

                        this.listProductSelecteds.forEach(itemProduct => {

                            listItemRequest.push({
                                id: itemProduct.id,
                                idProduct: itemProduct.idProduct,
                                qty: itemProduct.qty
                            });
                        })

                        this.templateRequest.listItemRequest = listItemRequest;

                        const result = await this.$store.dispatch("templateModule/CreateUpdate", this.templateRequest);

                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }
                    }
                }
            },

            focusInput(item) {
                this.arrayNodeProduct = []
                this.arrayNodeProduct.push(item.idCategory);
                if (item.idSubCategory != null && item.idSubCategory != undefined && item.idSubCategory != 0)  {
                    this.arrayNodeProduct.push(item.idSubCategory);
                }

                setTimeout(() => {
                    let txtComponent = document.getElementById(`productQty${item.idProduct}`);
                    txtComponent.focus();
                }, 100);

            },

            onOpenTreeViewProduct(items) {
                this.arrayNodeProduct = items
            },

            async deleteItem(item) {
                
                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                if (itemProduct.idTable == item.idProduct) {
                                    itemProduct.qty = '0.00'
                                }
                            });
                        }
                        else {
                            if (itemSubCategoryOrProduct.idTable == item.idProduct) {
                                itemSubCategoryOrProduct.qty = '0.00'
                            }
                        }
                    });
                });


                await this.updateItemsProduct();

            },

            async checkMandatory() {

                let allFieldsOK = true;
                let listProductName = ''

                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                allFieldsOK = this.validations.mandatoryProduct(itemProduct);

                                if (allFieldsOK == false) {
                                    listProductName += ` - ${itemProduct.name}<br />`;
                                }
                            });
                        }
                        else {
                            allFieldsOK = this.validations.mandatoryProduct(itemSubCategoryOrProduct);

                            if (allFieldsOK == false) {
                                listProductName += ` - ${itemSubCategoryOrProduct.name}<br />`;
                            }
                        }
                    });
                });

                if (listProductName != '') {
                    this.showToast("error", "Error!", `The items below are mandatory: <br /> <br /> ${listProductName}`);
                }

                return listProductName == '';
            },

            isProductMandatory(itemProduct) {
                return itemProduct.mandatoryPool == 1;
            },

            async showProductsImages(itemProduct) {

                let listParams = [];
                listParams.push({
                    typeAttachment: TYPE_ATTACHMENT.PRODUCT.value,
                    typeDocument: TYPE_DOCUMENT.PRODUCT.value,
                    idKey: itemProduct.idProduct,
                    isImage: true
                })

                this.dialogShowFiles = {
                    show: true,
                    showAsCarrousel: false,
                    reload: Math.random(),
                    listParams
                };
            },

            async showProductsDocs(itemProduct) {

                let listParams = [];
                listParams.push({
                    typeAttachment: TYPE_ATTACHMENT.PRODUCT.value,
                    typeDocument: TYPE_DOCUMENT.PRODUCT_DOCS.value,
                    idKey: itemProduct.idProduct,
                    isImageAndDoc: true
                })

                this.dialogShowFiles = {
                    show: true,
                    reload: Math.random(),
                    listParams
                };
            },
        },

        async created() {
            this.showLoading();

            await this.getRegister();
            await this.getLists();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }
</style>

<style>
    input[name="qtyField"] {
        text-align: center !important;
    }

    .v-text-field__suffix {
        font-size: 12px !important;
        font-weight: bold;
    }
</style>