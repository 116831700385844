import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":"900","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showImportTemplate),callback:function ($$v) {_vm.showImportTemplate=$$v},expression:"showImportTemplate"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_c(VCol,{attrs:{"cols":"6"}},[_vm._v(" Import Template ")])],1),_c(VCardText,{staticStyle:{"margin-top":"20px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('label',[_vm._v("Templates")]),_c(VCombobox,{attrs:{"items":_vm.listTemplate,"item-text":"nameWithUser","item-value":"id","clearable":"","outlined":"","dense":""},model:{value:(_vm.templateSelected),callback:function ($$v) {_vm.templateSelected=$$v},expression:"templateSelected"}})],1)],1)],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-center"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",staticStyle:{"background-color":"var(--color__red) !important","color":"#ffffff !important"},on:{"click":function($event){return _vm.cancel()}}},on),[_vm._v(" Cancel ")])]}}])},[_c('span',[_vm._v("Cancel")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.confirmImport()}}},on),[_vm._v(" Import ")])]}}])},[_c('span',[_vm._v("Import the template")])])],1)],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }